import * as React from 'react'
import { useTranslation } from '../../hooks'
import { useFormikContext } from 'formik'
import { EmployeeValues } from '../combined-values'
import { NumberInput, OptionsSelector, Dropdown } from '../../components'
import {
  Job,
  OVERTIME_OPTIONS,
  EEO_OPTIONS,
  getInitializeJobsValue
} from './jobs-and-pay-types'
import { DividingLine } from '@toasttab/buffet-pui-config-templates'
import { AdditionalJobsCard } from './AdditionalJobsCard'
import { JobsAndPay, Option } from '../../types'
import { Button } from '@toasttab/buffet-pui-buttons'
import { AddIcon, RestartIcon } from '@toasttab/buffet-pui-icons'
import { Currency, formatCurrency } from '@toasttab/buffet-pui-number-utilities'
import { TextInput } from '@toasttab/buffet-pui-text-input'
import {
  getSelectedLocationsExportIds,
  positionPaygroupHash
} from './JobsAndPayForm'
import {
  jobLocationHasPaygroupAssigned,
  jobPayGroupAlreadyAssigned,
  updateJobsList
} from './helpers'

const formatAsUSD = (amount: number) => {
  // this is specifically meant to convert to USD
  // eslint-disable-next-line @toasttab/buffet/hard-coded-currencies
  return formatCurrency(
    {
      amount,
      currency: Currency.USD
    },
    'en-US'
  )
}

type Props = {
  jobsAndPay: JobsAndPay
  isHourly: boolean
  isNewHire: boolean
  isPOS: boolean
  displayName: string
  jobsChanged: boolean
  eeoEnabled: boolean
  posJobsDisabled: boolean
  resetJobsValues: () => void
}

const PaymentDetailsCard = (props: Props) => {
  const {
    jobsAndPay,
    isHourly,
    isNewHire,
    isPOS,
    displayName,
    jobsChanged,
    eeoEnabled,
    posJobsDisabled,
    resetJobsValues
  } = props

  const { t } = useTranslation()

  const { setFieldValue, values, handleBlur } =
    useFormikContext<EmployeeValues>()

  const [showAccessCode, setShowAccessCode] = React.useState(
    getSelectedLocationsExportIds(values.jobs, jobsAndPay.locations).length > 0
  )

  // Preserve this link state across step transitions in case a user returns to this step
  const [syncPosAccessCode, setSyncPosAccessCode] = React.useState(
    values.employeeNumber === values.posAccessCode
  )

  const onUpdatePOSAccessCode = () => {
    if (syncPosAccessCode && values.posAccessCode !== values.employeeNumber) {
      setSyncPosAccessCode(false)
    }
  }

  const getJobsForLocation = (locId: string, jobId: string) => {
    const location = jobsAndPay.locations.find((loc) => loc.id === locId)
    const selectedJobs = values.jobs
    /*
      This code filters out previously selected jobs at a location and returns the rest of the jobs as a list
      Job Assignment 1 -> Location A, Job A
      Job Assignment 2 -> Location A, list will EXCLUDE Job A

      For the location selected for this current job list,
        - if the selected job (jobId) is the same as the one being checked in the filter, let it through so as to not
          disable the job selected at this assignment
        - if the job you are looking at is not in the list of selected jobs, show it
     */
    return (
      location?.jobs
        .filter((job) => {
          if (job.id === jobId) {
            return true
          }
          return selectedJobs.findIndex((sj) => sj.jobId === job.id) === -1
        })
        .map((job) => {
          return {
            label: job.name,
            value: job.id
          } as Option
        }) || []
    )
  }

  const locationOptions: Option[] = jobsAndPay.locations.map((loc) => {
    return {
      label: loc.name,
      value: loc.id,
      disabled: getJobsForLocation(loc.id, values.jobs[0].id).length === 0
    }
  })
  const workTaxLocationOptions: Option[] = jobsAndPay.workTaxLocations.map(
    (loc) => {
      return { label: loc.name, value: loc.id }
    }
  )
  const payGroupOptions: Option[] = jobsAndPay.payGroupsV2.map((pg) => {
    return { label: pg.name, value: pg.id }
  })
  const roleOptions: Option[] = jobsAndPay.payrollSecurityRoles.map((role) => {
    return { label: role.name, value: role.id }
  })

  const [firstJob, ...restJobs] = values.jobs

  const firstPositionDropdownOptions = positionPaygroupHash(jobsAndPay)

  const getJobDisabled = (locId: string) => {
    if (locId === '') {
      return true
    }
    const location = jobsAndPay.locations.find((loc) => loc.id === locId)
    const jobs = location?.jobs
    // only enable job input if there are multiple jobs to choose from
    if (jobs && jobs?.length < 1) {
      return true
    }
    return false
  }

  const deleteJob = (jobId: string) => {
    const jobs = [...values.jobs]
    const index = jobs.findIndex((job) => job.id === jobId)
    if (index > -1) {
      jobs.splice(index, 1)
    }
    setFieldValue('jobs', jobs)
  }

  const periodsPerYear = jobsAndPay.customerPositions.find(
    (cp) => cp.id === values.jobs[0].customerPositionId
  )?.checkCode.periodsPerYear

  const payPerPayPeriod = periodsPerYear
    ? formatAsUSD((values.salary || 0) / periodsPerYear)
    : undefined

  /**
   * We want to add job but enforce our business logic of using the same pay group
   * and work tax location if the job is for the same location. Since we can have a
   * location pre-selected, we need to update pay group and work tax location if they
   * fall in the same location as the primary job.
   */
  const getInitialJobAtLocation = (locId: string) => {
    const initLocJobs = getJobsForLocation(locId, values.jobs[0].id)
    return initLocJobs.length === 1 ? initLocJobs[0].value : ''
  }

  const getNextLocationWithUnassignedJob = () =>
    jobsAndPay.locations.find((loc) => {
      return getJobsForLocation(loc.id, values.jobs[0].id).length > 0
    })

  const addJob = () => {
    const {
      locationId,
      payGroupId,
      workTaxLocationId,
      id,
      customerPositionId
    } = firstJob
    const newRestJobs = [...restJobs, getInitializeJobsValue(jobsAndPay)].map(
      (job) => {
        const nextLocationWithUnassignedJob = getNextLocationWithUnassignedJob()

        const hasUnassignedJobsAtCurrentLocation =
          getJobsForLocation(locationId, id).length > 0

        const newLocationId = hasUnassignedJobsAtCurrentLocation
          ? locationId
          : nextLocationWithUnassignedJob!.id

        if (job.locationId === '') {
          return {
            ...job,
            jobId: getInitialJobAtLocation(newLocationId),
            locationId: newLocationId,
            payGroupId: payGroupId,
            workTaxLocationId: workTaxLocationId,
            customerPositionId: customerPositionId
          }
        }
        // This gets hit when only one location is available
        // The array for this .map() uses the function getInitializeJobsValue which, if there is 1 location,
        // we need to make sure to set the paygroup and work tax location properly. So, the location will only be
        // present in the function if there's 1 available location and if that's the case, it'll match the first job's location
        if (locationId === job.locationId) {
          return {
            ...job,
            payGroupId: payGroupId,
            workTaxLocationId: workTaxLocationId,
            customerPositionId: customerPositionId
          }
        }
        return { ...job }
      }
    )
    setFieldValue('jobs', [firstJob, ...newRestJobs])
  }

  const onLocationChange = (locId: string) => {
    const newRestJobs = restJobs.map((job) => {
      if (locId === job.locationId) {
        return {
          ...job,
          payGroupId: firstJob.payGroupId,
          workTaxLocationId: firstJob.workTaxLocationId,
          customerPositionId: firstJob.customerPositionId
        }
      }
      return { ...job }
    })
    const newJobs = [
      { ...firstJob, locationId: locId, jobId: getInitialJobAtLocation(locId) },
      ...newRestJobs
    ]
    setFieldValue('jobs', newJobs)
    const hasExportIds =
      getSelectedLocationsExportIds(newJobs, jobsAndPay.locations).length > 0
    setShowAccessCode(hasExportIds)
    setFieldValue('validateAccessCode', hasExportIds)
  }

  const onPayGroupChange = (payGroupId: string) => {
    const newRestJobs = restJobs.map((job) => {
      if (
        firstJob.locationId === job.locationId ||
        payGroupId === job.payGroupId
      ) {
        return {
          ...job,
          payGroupId,
          customerPositionId: firstJob.customerPositionId,
          workTaxLocationId: firstJob.workTaxLocationId
        }
      }
      return { ...job }
    })
    const newPositionOptions = firstPositionDropdownOptions[payGroupId]
    setFieldValue('jobs', [
      {
        ...firstJob,
        payGroupId: payGroupId,
        customerPositionId:
          newPositionOptions?.length === 1 ? newPositionOptions[0].id : ''
      },
      ...newRestJobs
    ])
  }

  const onWTLChange = (wtl: string) => {
    const newRestJobs = restJobs.map((job) => {
      if (firstJob.locationId === job.locationId) {
        return {
          ...job,
          payGroupId: firstJob.payGroupId,
          workTaxLocationId: wtl
        }
      } else if (firstJob.payGroupId === job.payGroupId) {
        return {
          ...job,
          workTaxLocationId: wtl
        }
      }
      return { ...job }
    })
    setFieldValue('jobs', [
      { ...firstJob, workTaxLocationId: wtl },
      ...newRestJobs
    ])
  }

  const onCustomerPositionChange = (pos: string) => {
    const newRestJobs = restJobs.map((job) => {
      if (firstJob.locationId === job.locationId) {
        return {
          ...job,
          payGroupId: firstJob.payGroupId,
          customerPositionId: pos
        }
      } else if (firstJob.payGroupId === job.payGroupId) {
        return {
          ...job,
          customerPositionId: pos
        }
      }
      return { ...job }
    })
    setFieldValue('jobs', [
      { ...firstJob, customerPositionId: pos },
      ...newRestJobs
    ])
  }

  const jobsList = (job: Job) => {
    const updatedJobs = updateJobsList(
      values.jobs,
      job,
      firstPositionDropdownOptions
    )
    setFieldValue('jobs', updatedJobs)
    const hasExportIds =
      getSelectedLocationsExportIds(updatedJobs, jobsAndPay.locations).length >
      0
    setShowAccessCode(hasExportIds)
    setFieldValue('validateAccessCode', hasExportIds)
  }

  const WTLDropdown = (
    <Dropdown
      fieldName='jobs.0.workTaxLocationId'
      label={t('workTaxLocation')}
      onChange={(val) => onWTLChange(val)}
      options={workTaxLocationOptions}
      enableSearch
    />
  )

  const PayGroupDropdown = (
    <Dropdown
      fieldName='jobs.0.payGroupId'
      label={t('payGroup')}
      onChange={(val) => onPayGroupChange(val)}
      options={payGroupOptions}
      helperText={payGroupOptions.length === 1 ? t('singlePayGroup') : null}
      enableSearch
    />
  )

  const CustomerPositionDropdown = firstPositionDropdownOptions[
    values.jobs[0].payGroupId
  ]?.length > 0 && (
    <Dropdown
      fieldName='jobs.0.customerPositionId'
      label={t('position')}
      onChange={(val) => onCustomerPositionChange(val)}
      options={firstPositionDropdownOptions[values.jobs[0].payGroupId].map(
        (cp) => ({
          label: cp.name,
          value: cp.id
        })
      )}
      disabled={values.jobs[0].payGroupId === ''}
      helperText={t('positionHelper')}
      enableSearch
    />
  )

  return (
    <>
      <div className='flex justify-between mb-6 font-bold type-headline-4 text-default'>
        <div>{t('jobsAndPay')}</div>
        {jobsChanged ? (
          <Button
            variant='text-link'
            testId='revert-to-original-button'
            iconLeft={<RestartIcon accessibility='decorative' />}
            onClick={resetJobsValues}
            className='-my-2 md:-my-1'
            data-toast-track-id='add-employee-revert-button'
          >
            {t('revertToOriginal')}
          </Button>
        ) : null}
      </div>
      {isHourly && (
        <>
          <div className='gap-6 lg:flex'>
            <Dropdown
              fieldName='jobs.0.locationId'
              label={t('location')}
              onChange={(val) => onLocationChange(val)}
              options={locationOptions}
              disabled={isPOS}
              enableSearch
            />
            <Dropdown
              fieldName='jobs.0.jobId'
              label={t('job')}
              options={getJobsForLocation(
                firstJob.locationId,
                values.jobs[0].jobId
              )}
              disabled={getJobDisabled(firstJob.locationId) || posJobsDisabled}
              enableSearch
            />
          </div>
          <div className='gap-6 lg:flex'>
            <NumberInput
              required
              prefix='$'
              fieldName='jobs.0.wage'
              label={t('hourlyPay')}
              currency
              onBlur={handleBlur}
            />
            {PayGroupDropdown}
          </div>
          <div className='gap-6 lg:flex'>
            {WTLDropdown}
            {CustomerPositionDropdown}
          </div>
        </>
      )}
      {!isHourly && (
        <>
          <div className='gap-6 lg:flex'>
            <NumberInput
              required
              prefix='$'
              fieldName='salary'
              label={t('salary')}
              currency
              allowNegative={false}
              onBlur={handleBlur}
            />
            <TextInput
              containerClassName={'w-full mb-4 md:w-80 md:mb-6'}
              name='salaryPerPay'
              label={t('salaryPerPay')}
              value={payPerPayPeriod || 'N/A'}
              readOnly
              size='lg'
            />
          </div>
          <div className='gap-6 lg:flex'>
            {PayGroupDropdown}
            {CustomerPositionDropdown}
          </div>
        </>
      )}
      <OptionsSelector
        fieldName='overtimeEligible'
        label={t('isOvertimeEligible', { name: displayName })}
        options={OVERTIME_OPTIONS.map(({ labelKey, value, helpTextKey }) => {
          return {
            label: t(labelKey),
            value,
            helpText: t(helpTextKey),
            disabled:
              (isHourly && values.taxationType === 'W2' && labelKey === 'no') ||
              (values.taxationType === '1099' && labelKey === 'yes')
          }
        })}
        onChange={(val) => setFieldValue('overtimeEligible', val === 'true')}
        singleOptionHelperText={
          isHourly && values.taxationType === 'W2'
            ? t('hourlyOvertimeHelperText')
            : values.taxationType === '1099'
            ? t('overtime1099HelperText')
            : null
        }
        rightAlignedHelperText={values.taxationType === 'W2' ? true : false}
      />

      {!isHourly && (
        <>
          <div>
            <DividingLine />
          </div>
          <div className='gap-6 lg:flex'>
            <Dropdown
              fieldName='jobs.0.locationId'
              label={t('location')}
              onChange={(val) => onLocationChange(val)}
              options={locationOptions}
              disabled={isPOS}
              enableSearch
            />
            <Dropdown
              fieldName='jobs.0.jobId'
              label={t('job')}
              options={getJobsForLocation(
                firstJob.locationId,
                values.jobs[0].jobId
              )}
              disabled={getJobDisabled(firstJob.locationId) || posJobsDisabled}
              enableSearch
            />
          </div>
          {WTLDropdown}
        </>
      )}
      {(!isPOS || values.jobs.length > 1) && (
        <div>
          <DividingLine />
        </div>
      )}
      {restJobs.map((job: Job, index: number) => (
        <AdditionalJobsCard
          key={job.id}
          index={index + 1}
          isPOS={isPOS}
          workTaxLocationOptions={workTaxLocationOptions}
          payGroupOptions={payGroupOptions}
          locationOptions={locationOptions}
          jobOptions={getJobsForLocation(job.locationId, job.jobId)}
          jobDisabled={getJobDisabled(firstJob.locationId)}
          isHourly={isHourly}
          job={job}
          customerPositionDropdownOptions={
            positionPaygroupHash(jobsAndPay)[values.jobs[index + 1].payGroupId]
          }
          updateJobsList={jobsList}
          deleteJob={deleteJob}
          disablePayGroup={jobLocationHasPaygroupAssigned(values.jobs, job)}
          disableWTL={
            jobLocationHasPaygroupAssigned(values.jobs, job) ||
            jobPayGroupAlreadyAssigned(values.jobs, job)
          }
          getRemainingJobs={getJobsForLocation}
        />
      ))}
      {!isPOS && (
        <div>
          {getNextLocationWithUnassignedJob() !== undefined && (
            <>
              <Button
                variant='text-link'
                testId='add-job-button'
                iconLeft={<AddIcon accessibility='decorative' />}
                onClick={addJob}
                data-toast-track-id={`add-employee-add-job-button`}
              >
                {t('addJob')}
              </Button>
            </>
          )}
        </div>
      )}
      {!isPOS && showAccessCode && isNewHire && (
        <>
          <div className='mb-4'>
            <DividingLine />
          </div>
          <NumberInput
            decimalScale={0}
            required
            fieldName='posAccessCode'
            label={t('posAccessCode')}
            helperText={t('posAccessCodeSubtext')}
            containerClassName='mb-4 w-80'
            onBlur={handleBlur}
            onChange={onUpdatePOSAccessCode}
            maxLength={8}
          />
        </>
      )}
      {eeoEnabled && (
        <>
          <div className='mb-4'>
            <DividingLine />
          </div>
          <div className='mb-6 font-bold type-headline-4 text-default'>
            {t('eeoTitle')}
          </div>
          <Dropdown
            fieldName='eeoClassification'
            label={t('eeoClassification')}
            options={EEO_OPTIONS.map((o) => ({
              label: t(o.labelKey),
              value: o.value
            }))}
            helperText={t('eeoHelperText')}
            enableSearch
          />
        </>
      )}
      <div className='mb-4'>
        <DividingLine />
      </div>
      <div className='mb-6 font-bold type-headline-4 text-default'>
        {t('permissions')}
      </div>
      <Dropdown
        fieldName='securityRole'
        label={t('securityRole')}
        options={roleOptions}
        helperText={t('securityHelperText')}
        enableSearch
      />
    </>
  )
}

export { PaymentDetailsCard }
